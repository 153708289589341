import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Typography,
  Box,
  Container,
  CircularProgress,
  Card,
  CardContent,
  Link,
  ThemeProvider,
  createTheme,
  styled,
} from '@mui/material';
import Footer from './Footer';

const companyLogos: Record<
  string,
  { src: string; width: string; height: string }
> = {
  stockX: { src: '/StockX-2.png', width: '160px', height: '35px' },
  flightClub: { src: '/Flight-Club-Logo.png', width: '160px', height: '40px' },
  goat: { src: '/GOAT-Logo.png', width: '160px', height: '45px' },
};

// Theme setup
const theme = createTheme({
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
});

const StyledCard = styled(Card)({
  margin: '16px',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  boxShadow: '0 3px 5px rgba(0,0,0,0.2)',
});

const StyledCardMedia = styled('img')({
  width: '100%',
  maxWidth: '400px',
  height: 'auto',
  objectFit: 'cover',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  '@media (max-width: 600px)': {
    maxWidth: '200px', // Smaller width for mobile devices
  },
});

const StyledCardContent = styled(CardContent)({
  textAlign: 'center',
  width: '100%',
});

interface ShoePriceMap {
  [size: string]: number;
}

interface ShoeResellLinks {
  [platform: string]: string;
}

interface ShoeData {
  thumbnail: string;
  shoeName: string;
  description: string;
  colorway: string;
  retailPrice: number;
  releaseDate: string;
  priceMap: {
    [platform: string]: ShoePriceMap;
  };
  resellLinks: ShoeResellLinks;
  lowestResellPrice: {
    stockX: number;
    goat: number;
    flightClub: number;
  };
}

const ShoeSelection: React.FC = () => {
  const { styleID } = useParams();
  const [shoeData, setShoeData] = useState<ShoeData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://475ygw6grh.execute-api.us-west-1.amazonaws.com/prod/getProductPrices?styleID=${styleID}`,
      )
      .then((response) => {
        setShoeData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Failed to fetch shoe prices', error);
        setLoading(false);
      });
  }, [styleID]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
        }}
      >
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          <Box sx={{ padding: 4 }}>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60vh',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Typography variant="h4" gutterBottom>
                  {shoeData?.shoeName}
                </Typography>
                {shoeData ? (
                  <StyledCard>
                    <StyledCardMedia
                      src={shoeData.thumbnail}
                      alt={`Image of ${shoeData.shoeName}`}
                    />
                    <StyledCardContent>
                      <Typography gutterBottom variant="h5">
                        {shoeData.shoeName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {shoeData.description}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 2 }}>
                        Colorway: {shoeData.colorway}
                      </Typography>
                      <Typography variant="body1">
                        Retail Price: ${shoeData.retailPrice}
                      </Typography>
                      <Typography variant="body1">
                        Release Date: {shoeData.releaseDate}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">
                          Lowest Resell Prices:
                        </Typography>
                        {Object.entries(shoeData.lowestResellPrice).map(
                          ([site, price]) => {
                            console.log(`Site: ${site}, Price: ${price}`);
                            return (
                              <Box
                                key={site}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  mb: 1,
                                  justifyContent: 'center',
                                  marginTop: 2,
                                }}
                              >
                                <img
                                  src={
                                    companyLogos[
                                      site as keyof typeof companyLogos
                                    ].src
                                  }
                                  alt={`${site} logo`}
                                  style={{
                                    width:
                                      companyLogos[
                                        site as keyof typeof companyLogos
                                      ].width,
                                    height:
                                      companyLogos[
                                        site as keyof typeof companyLogos
                                      ].height,
                                    marginRight: '8px',
                                  }}
                                />
                                <Typography>
                                  <Link
                                    href={
                                      shoeData.resellLinks[
                                        site as keyof typeof shoeData.resellLinks
                                      ]
                                    }
                                    target="_blank"
                                    sx={{
                                      textDecoration: 'none',
                                      color: 'blue',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    ${price}
                                  </Link>
                                </Typography>
                              </Box>
                            );
                          },
                        )}
                      </Box>
                    </StyledCardContent>
                  </StyledCard>
                ) : (
                  <Typography variant="body2">
                    No price data available.
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default ShoeSelection;
