import React from 'react';
import {
  Box,
  Typography,
  Link,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
});

const Footer: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#252525',
          color: 'white',
          padding: '2rem 0',
          textAlign: 'center',
          fontFamily: 'inherit', // Apply Lato font here
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
            marginBottom: '1rem',
            fontFamily: 'inherit', // Apply Lato font here
          }}
        >
          <Link
            href="https://twitter.com/solepredict"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <TwitterIcon />
          </Link>
          <Link
            href="https://www.instagram.com/solepredict/"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <InstagramIcon />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61553958581026"
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
          >
            <FacebookIcon />
          </Link>
        </Box>
        <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
          © 2024, SolePredict, All Rights Reserved
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default Footer;
