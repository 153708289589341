import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Menu,
  MenuItem,
  Box,
  TextField,
  Popover,
  ClickAwayListener,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Hub } from '@aws-amplify/core';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import debounce from 'lodash.debounce';
import axios from 'axios';

const Navbar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchAnchorEl, setSearchAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    checkUser();
    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn' || event === 'signOut') {
        checkUser();
      }
    });
  }, []);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const isPremiumSubscriber =
        user.attributes['custom:subscriptionStatus'] === 'subscribed'; // Assuming 'subscribed' is the value for premium users
      setSubscriptionStatus(isPremiumSubscriber ? 'premium' : 'free');
      setIsUserLoggedIn(true);
    } catch {
      setIsUserLoggedIn(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      setIsUserLoggedIn(false);
      setMobileOpen(false); // Ensure the mobile navigation drawer is closed.
      setAnchorEl(null); // Close the dropdown menu by removing its anchor.
      navigate('/'); // Navigate the user to the homepage or login page as needed.
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const debounceSearch = useCallback(
    debounce((value) => {
      if (!value.trim()) {
        setSearchResults([]);
        return;
      }

      axios
        .get(
          `https://475ygw6grh.execute-api.us-west-1.amazonaws.com/prod/getProducts`,
          {
            params: { keyword: value.trim(), limit: 5 },
          },
        )
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          console.error('Search failed:', error);
          setSearchResults([]);
        });
    }, 300),
    [],
  );

  useEffect(() => {
    return () => debounceSearch.cancel();
  }, [debounceSearch]);

  const handleFocus = () => {
    setSearchAnchorEl(inputRef.current);
  };

  const handleBlur = () => {
    // Delay the blur to check if the new active element requires keeping the popover open
    setTimeout(() => {
      if (
        !document.activeElement?.closest('.MuiPopover-root') &&
        !document.activeElement?.closest('.MuiTextField-root')
      ) {
        setSearchAnchorEl(null);
      }
    }, 100);
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchQuery(value);
      if (value.trim()) {
        debounceSearch(value);
      } else {
        setSearchResults([]);
      }
    },
    [debounceSearch],
  );

  const handleSearchKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      if (searchQuery) {
        navigate(`/price-comparison?query=${searchQuery}`);
      }
      setSearchResults([]);
      setSearchQuery('');
      event.preventDefault(); // Prevent the form from being submitted
    }
  };

  const handleSearchSelect = (styleID: string) => {
    navigate(`/shoe-selection/${styleID}`);
    setSearchResults([]);
    setSearchQuery('');
    setSearchAnchorEl(null);
  };

  const drawer = (
    <div>
      <List>
        {['Home', 'About', 'Premium', 'Articles'].map((text, index) => {
          let path;
          switch (text) {
            case 'Home':
              path = '/';
              break;
            case 'About':
              path = '/about-solepredict';
              break;
            case 'Premium':
              path =
                subscriptionStatus === 'premium' ? '/premium' : '/subscribe';
              break;
            case 'Articles':
              path = '/articles';
              break;
            default:
              path = '/';
              break;
          }
          return (
            <ListItem key={text} onClick={handleDrawerToggle}>
              <Link
                to={path}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  width: '100%',
                }}
              >
                <ListItemText
                  primary={text}
                  sx={{ fontFamily: 'Lato, sans-serif' }}
                />
              </Link>
            </ListItem>
          );
        })}
        {!isUserLoggedIn && (
          <>
            <ListItem
              button
              component={Link}
              to="/login"
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Log In"
                sx={{ fontFamily: 'Lato, sans-serif' }}
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/signup"
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Sign Up"
                sx={{ fontFamily: 'Lato, sans-serif' }}
              />
            </ListItem>
          </>
        )}
        {isUserLoggedIn && (
          <>
            <ListItem
              button
              component={Link}
              to="/profile"
              onClick={handleDrawerToggle}
            >
              <ListItemText
                primary="Profile"
                sx={{ fontFamily: 'Lato, sans-serif' }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                handleLogout();
                handleDrawerToggle();
              }}
            >
              <ListItemText
                primary="Log Out"
                sx={{ fontFamily: 'Lato, sans-serif' }}
              />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          fontFamily: 'Lato, sans-serif',
          boxShadow: 'none',
          marginTop: '10px',
          //borderBottom: '1px solid black',
        }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="BlackShoeVector.svg"
              alt="SolePredict Logo"
              style={{ height: '80px' }}
              // , marginRight: '20px'
            />
          </Link>

          <Hidden mdDown>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                marginLeft: isUserLoggedIn ? '330px' : '450px', // Adjust the values as needed
              }}
            >
              {['Home', 'About', 'Premium', 'Articles'].map((text) => {
                let path = '/';
                if (text === 'Home') path = '/';
                else if (text === 'About') path = '/about-solepredict';
                else if (text === 'Premium')
                  path =
                    subscriptionStatus === 'premium'
                      ? '/premium'
                      : '/subscribe';
                else if (text === 'Articles') path = '/articles';
                return (
                  <Link
                    key={text}
                    to={path}
                    style={{
                      textDecoration: 'none',
                      color: '#252525',
                      margin: '0 30px',
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: 'inherit', fontWeight: 'bold' }}
                    >
                      {text}
                    </Typography>
                  </Link>
                );
              })}
            </Box>
          </Hidden>

          <ClickAwayListener onClickAway={handleBlur}>
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyPress={handleSearchKeyPress}
                onFocus={handleFocus}
                variant="outlined"
                placeholder="Search sneakers and hype"
                size="small"
                sx={{
                  bgcolor: '#F5F5F5',
                  width: {
                    xs: '255px', // width for extra-small screens
                    sm: '350px', // width for small and larger screens
                  },
                  marginRight: {
                    xs: '5px', // margin for extra-small screens
                    sm: '30px', // margin for small and larger screens
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  inputProps: {
                    ref: inputRef,
                    style: {
                      fontFamily: 'Lato, sans-serif',
                      //fontWeight: 'bold',
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily: 'Lato, sans-serif',
                    //fontWeight: 'bold',
                  },
                }}
              />
              <Popover
                open={Boolean(searchAnchorEl && searchResults.length > 0)}
                anchorEl={searchAnchorEl}
                onClose={() => setSearchAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                disableAutoFocus
                disableEnforceFocus
                PaperProps={{
                  style: { marginLeft: '-22px' },
                }}
              >
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    bgcolor: 'background.paper',
                  }}
                >
                  {searchResults.map((item) => (
                    <ListItem
                      button
                      key={item._id}
                      onClick={() => handleSearchSelect(item.styleID)}
                    >
                      <ListItemAvatar>
                        <img
                          src={item.thumbnail}
                          alt={`Thumbnail for ${item.shoeName}`}
                          style={{
                            width: 85,
                            height: 60,
                            objectFit: 'cover',
                            marginRight: '16px',
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.shoeName}
                        secondary={`$${
                          item.lowestResellPrice?.stockX ?? 'N/A'
                        }`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Popover>
            </Box>
          </ClickAwayListener>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden smDown>
              {isUserLoggedIn ? (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleProfileMenu}
                  color="inherit"
                  style={{ color: '#252525' }}
                >
                  <AccountCircle fontSize="large" />
                </IconButton>
              ) : (
                <>
                  <Link
                    to="/login"
                    style={{
                      textDecoration: 'none',
                      marginRight: '10px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'inherit',
                        border: '1px solid black', // Black border by default
                        padding: '6px 12px', // Add padding around the text
                        borderRadius: '3px', // Optional: adds rounded corners
                        background: 'transparent', // Ensure background is transparent by default
                        color: '#252525', // Default text color
                        ':hover': {
                          color: 'white',
                          background: '#0C9FD4',
                          border: '1px solid #0C9FD4', // Change border color on hover to match background
                        },
                      }}
                    >
                      Log In
                    </Typography>
                  </Link>
                  <Link
                    to="/signup"
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                      background: '#0C9FD4',
                      borderRadius: '3px',
                      padding: '6px 12px',
                    }}
                  >
                    <Typography variant="body1" sx={{ fontFamily: 'inherit' }}>
                      Sign Up
                    </Typography>
                  </Link>
                </>
              )}
            </Hidden>
            <Hidden mdUp>
              <IconButton
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                style={{ color: '#252525' }}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ fontFamily: 'Lato, sans-serif' }}
        color="#252525"
      >
        <MenuItem
          onClick={() => navigate('/profile')}
          sx={{ fontFamily: 'inherit' }}
          color="#252525"
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          sx={{ fontFamily: 'inherit' }}
          color="#252525"
        >
          Log Out
        </MenuItem>
      </Menu>

      <nav>
        <Hidden mdUp>
          <Drawer
            anchor="right"
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{ '.MuiDrawer-paper': { fontFamily: 'Lato, sans-serif' } }}
            color="#252525"
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default Navbar;
