import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listArticles } from '../graphql/queries';
import { ListArticlesQuery, Article } from '../API';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
} from '@mui/material';
import Footer from './Footer';

const Articles: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const articleData = (await API.graphql(
          graphqlOperation(listArticles),
        )) as {
          data: ListArticlesQuery;
        };
        const articles = articleData.data.listArticles?.items as Article[];
        setArticles(articles);
      } catch (err) {
        console.log('error fetching articles', err);
      }
    };

    fetchArticles();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'white',
        fontFamily: 'Lato, sans-serif',
      }}
    >
      <Container maxWidth="lg" sx={{ flexGrow: 1, py: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: 'center', mt: 4 }}
        >
          Articles
        </Typography>
        <Grid container spacing={4}>
          {articles.map((article) => (
            <Grid item xs={12} sm={6} md={4} key={article.id}>
              <Card>
                <CardActionArea href={`/article/${article.id}`}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {article.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {new Date(article.createdAt).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </Box>
  );
};

export default Articles;
