import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import the theme you created

import HomePage from './components/HomePage';
import UpcomingPage from './components/UpcomingPage';
import AboutPage from './components/AboutPage';
import Navbar from './components/Navbar';
import SignUp from './components/SignUp';
import EmailVerification from './components/EmailVerification';
import Profile from './components/Profile';
import Login from './components/Login';
import SubscribeLogin from './components/SubscribeLogin';
import Premium from './components/Premium';
import Subscribe from './components/Subscribe';
import EditProfile from './components/EditProfile';
import PasswordReset from './components/PasswordReset';
import PriceComparison from './components/PriceComparison';
import ShoeSelection from './components/ShoeSelection';
import Articles from './components/Articles'; // Import Articles component
import Article from './components/Article'; // Import Article component

import { Auth } from '@aws-amplify/auth';

Auth.configure({
  region: 'us-west-1',
  userPoolId: 'us-west-1_ns1iVmY4V',
  userPoolWebClientId: '1k5d33vav6tsd53d6julchibff',
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/upcoming" element={<UpcomingPage />} />
          <Route path="/about-solepredict" element={<AboutPage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/subscribe-login" element={<SubscribeLogin />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/price-comparison" element={<PriceComparison />} />
          <Route path="/shoe-selection/:styleID" element={<ShoeSelection />} />
          <Route path="/articles" element={<Articles />} />{' '}
          {/* Add Articles route */}
          <Route path="/article/:id" element={<Article />} />{' '}
          {/* Add Article route */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
