import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  Grid,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import Footer from './Footer';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h3: {
      fontWeight: 600,
    },
    body1: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
});

const AboutPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
          }}
        >
          <Container maxWidth="md">
            <Paper
              sx={{
                padding: 3,
                boxShadow: '0 3px 5px 2px rgba(27, 27, 27, .3)',
                backgroundColor: '#F5F5F5',
              }}
              elevation={5}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={5}>
                  <img
                    src={`${process.env.PUBLIC_URL}/shutterstock_2038299845.jpg`}
                    alt="Sneaker Market Analysis"
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 8,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    variant="h3"
                    sx={{
                      marginBottom: 2,
                      color: '#252525',
                      fontWeight: 600,
                      fontFamily: 'inherit',
                    }}
                  >
                    About SolePredict
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: '#252525',
                      fontFamily: 'inherit',
                    }}
                  >
                    SolePredict combines the passion of the streetwear scene
                    with cutting-edge technology and data analytics. We leverage
                    machine learning algorithms and a vast database of
                    historical sneaker sales to predict the future market value
                    of upcoming releases. Our objective is to give sneakerheads
                    and investors alike the data they need to make informed
                    decisions in the dynamic world of sneaker trading.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>

        {/* Spacer Box */}
        <Box sx={{ flex: '0 0 auto' }} />

        {/* Footer */}
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default AboutPage;
