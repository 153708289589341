import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Link,
} from '@mui/material';
import Footer from './Footer';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await Auth.signIn(email, password);
      navigate('/subscribe');
    } catch (error) {
      const typedError = error as { code?: string }; // Type assertion
      if (typedError.code === 'UserNotConfirmedException') {
        // Redirect to the verification page
        navigate('/verify-email', { state: { email: email } });
      } else {
        // Other error handling
        setError('Failed to log in. Please check your credentials.');
      }
      console.error('Error signing in: ', error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'white',
        fontFamily: 'Lato, sans-serif', // Apply Lato font here
      }}
    >
      {/* Content Box */}
      <Box
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'inherit', // Ensure inheritance of Lato font
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            fontFamily: 'Lato, sans-serif',
            border: '2px solid #ccc', // Add border around the content
            padding: '30px', // Add padding for better spacing
            borderRadius: '10px', // Add border radius
            marginTop: '2rem', // Move the content up on the page
          }}
        >
          <Box
            sx={{
              marginTop: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontFamily: 'inherit' }}
            >
              {' '}
              {/* Ensures Lato font is used */}
              Log In to Subscribe
            </Typography>
            <Box
              component="form"
              onSubmit={handleLogin}
              noValidate
              sx={{ mt: 1, textAlign: 'center' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ fontFamily: 'inherit' }} // Ensures Lato font is used
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ fontFamily: 'inherit' }} // Ensures Lato font is used
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2, // Adjusted margin-bottom to reduce the gap
                  width: '110px',
                  fontFamily: 'inherit',
                  backgroundColor: '#0C9FD4',
                  borderRadius: '10px',
                }} // Ensures Lato font is used
              >
                Log In
              </Button>
              <Box sx={{ textAlign: 'center', mt: 1 }}>
                <Link
                  component={RouterLink}
                  to="/password-reset"
                  sx={{
                    color: '#0C9FD4',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    display: 'block', // Make the link take up a full block to ensure it goes to the next line
                  }}
                >
                  Forgot your password?
                </Link>
              </Box>
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  fontFamily: 'Lato, sans-serif',
                  color: '#252525',
                  textAlign: 'center',
                }}
              >
                New to SolePredict?{' '}
                <Link
                  component={RouterLink}
                  to="/signup"
                  sx={{
                    color: '#0C9FD4',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Sign up!
                </Link>
              </Typography>
              {error && (
                <Typography
                  color="error"
                  variant="body2"
                  sx={{ fontFamily: 'inherit', textAlign: 'center' }}
                >
                  {' '}
                  {/* Ensures Lato font is used */}
                  {error}
                </Typography>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Login;
