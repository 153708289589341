import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Button,
  ThemeProvider,
  createTheme,
  styled,
} from '@mui/material';
import Footer from './Footer';
import { useInView } from 'react-intersection-observer';

const StyledCard = styled(Card)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledCardMedia = styled('img')({
  height: 250,
  width: '100%',
  objectFit: 'cover',
});

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

interface Sneaker {
  _id: string;
  shoeName: string;
  colorway: string;
  description: string;
  releaseDate: string;
  retailPrice: number;
  styleID: string;
  imageLinks: string[];
  thumbnail: string;
  lowestResellPrice: {
    stockX: number;
    flightClub: number;
    goat: number;
  };
  resellLinks: {
    stockX: string;
    flightClub: string;
    goat: string;
  };
}

const useQuery = () => new URLSearchParams(useLocation().search);

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
});

const PriceComparison: React.FC = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const searchQuery = query.get('query');
  const [sneakers, setSneakers] = useState<Sneaker[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const itemsPerPage = 10;

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const fetchSneakers = useCallback(async (keyword: string, page: number) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://475ygw6grh.execute-api.us-west-1.amazonaws.com/prod/getProducts`,
        {
          params: { keyword, page, pageSize: itemsPerPage },
        },
      );
      const newSneakers = Array.isArray(response.data) ? response.data : [];
      setSneakers((prevSneakers) => [...prevSneakers, ...newSneakers]);
      setHasMore(newSneakers.length === itemsPerPage);
      setCurrentPage(page);
    } catch (error) {
      console.error('Failed to fetch sneakers', error);
      setHasMore(false);
    }
    setIsLoading(false);
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setSneakers([]);
      setCurrentPage(1);
      setHasMore(true);
      setIsInitialLoad(true);
      fetchSneakers(searchQuery, 1);
    }
  }, [searchQuery, fetchSneakers]);

  useEffect(() => {
    if (inView && hasMore && !isLoading && !isInitialLoad) {
      fetchSneakers(searchQuery || '', currentPage + 1);
    }
  }, [
    inView,
    hasMore,
    isLoading,
    searchQuery,
    currentPage,
    fetchSneakers,
    isInitialLoad,
  ]);

  const handleSelectSneaker = (sneaker: Sneaker) => {
    navigate(`/shoe-selection/${sneaker.styleID}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
        }}
      >
        <Container maxWidth="lg" sx={{ flexGrow: 1 }}>
          <Box sx={{ my: 5 }}>
            <Typography variant="h5" gutterBottom>
              Browse results for &quot;{searchQuery}&quot;
            </Typography>
            {isLoading && isInitialLoad ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60vh',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={2}>
                {sneakers.map((sneaker) => (
                  <Grid item xs={12} sm={6} md={4} key={sneaker._id}>
                    <StyledCard>
                      <StyledCardMedia
                        src={sneaker.thumbnail}
                        alt={sneaker.shoeName}
                      />
                      <StyledCardContent>
                        <Typography variant="h6" gutterBottom>
                          {sneaker.shoeName}
                        </Typography>
                        <Button
                          variant="outlined"
                          onClick={() => handleSelectSneaker(sneaker)}
                          sx={{
                            color: 'white',
                            background: '#0C9FD4',
                            border: '1px solid #0C9FD4',
                          }}
                        >
                          View Prices
                        </Button>
                      </StyledCardContent>
                    </StyledCard>
                  </Grid>
                ))}
                {isLoading && (
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </Grid>
                )}
                <div ref={ref} />
              </Grid>
            )}
          </Box>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default PriceComparison;
