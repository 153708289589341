import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { getArticle } from '../graphql/queries';
import { GetArticleQuery, Article as ArticleType } from '../API';
import {
  ThemeProvider,
  createTheme,
  Box,
  Typography,
  Container,
  CardMedia,
  Button,
} from '@mui/material';
import Footer from './Footer';

const theme = createTheme({
  typography: {
    fontFamily: 'Lato, Arial, sans-serif',
    h4: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
  },
});

interface thisArticle {
  id: string;
  slug: string;
  title: string;
  body: string;
  author: string;
  date: Date;
  image_url: string;
}

const Article: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [article, setArticle] = useState<thisArticle | null>(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const articleData = (await API.graphql(
          graphqlOperation(getArticle, { id }),
        )) as {
          data: GetArticleQuery;
        };
        const article = articleData.data.getArticle as ArticleType;
        setArticle(article);
      } catch (err) {
        console.log('error fetching article', err);
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) return <Typography>Loading...</Typography>;

  // Use the slug as the URL
  const shoeUrl = `${article.slug}`;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          fontFamily: 'Lato, sans-serif',
        }}
      >
        <Container maxWidth="md" sx={{ flexGrow: 1, py: 5 }}>
          <Typography variant="h4" gutterBottom>
            {article.title}
          </Typography>
          {article.image_url && (
            <CardMedia
              component="img"
              height="400"
              image={article.image_url}
              alt={article.title}
              sx={{ marginBottom: 2 }}
            />
          )}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ marginBottom: 2 }}
          >
            By {article.author} on {new Date(article.date).toLocaleDateString()}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            {article.body}
          </Typography>

          {/* Button to link to the shoe URL */}
          <Button
            variant="contained"
            color="primary"
            href={shoeUrl}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mt: 2 }}
          >
            Click here to go to the drop
          </Button>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Article;
