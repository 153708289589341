import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  Link,
  ThemeProvider,
} from '@mui/material';
import Footer from './Footer';
import theme from './theme';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await Auth.signIn(email, password);
      navigate('/');
    } catch (error) {
      const typedError = error as { code?: string }; // Type assertion
      if (typedError.code === 'UserNotConfirmedException') {
        // Redirect to the verification page
        navigate('/verify-email', { state: { email: email } });
      } else {
        // Other error handling
        setError('Failed to log in. Please check your credentials.');
      }
      console.error('Error signing in: ', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          background: 'white',
          alignItems: 'center',
          mt: -6, // Move the entire Box 50px up
        }}
      >
        {/* Content Box */}
        <Box
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2, // Optional: add padding for better spacing
            mt: 6, // Add margin-top to move the content down within the Box
          }}
        >
          <Container
            component="main"
            maxWidth="xs"
            sx={{
              border: '1px solid #ccc', // Add border around the container
              padding: 3, // Add padding inside the container
              borderRadius: 2, // Optional: add border radius for rounded corners
            }}
          >
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h4">
                Log In
              </Typography>
              <Box
                component="form"
                onSubmit={handleLogin}
                noValidate
                sx={{ mt: 1, textAlign: 'center' }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    width: '110px',
                    backgroundColor: '#0C9FD4',
                    borderRadius: '10px',
                    textTransform: 'none', // Prevent button text from being capitalized
                  }}
                >
                  Log In
                </Button>
                <Link
                  component={RouterLink}
                  to="/password-reset"
                  sx={{
                    mt: 1,
                    color: '#0C9FD4',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    display: 'block',
                  }}
                >
                  Forgot your password?
                </Link>
                <Typography
                  variant="body2"
                  sx={{
                    mt: 3,
                    color: '#252525',
                    textAlign: 'center',
                  }}
                >
                  New to SolePredict?{' '}
                  <Link
                    component={RouterLink}
                    to="/signup"
                    sx={{
                      color: '#0C9FD4',
                      textDecoration: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    Sign up!
                  </Link>
                </Typography>
                {error && (
                  <Typography
                    color="error"
                    variant="body2"
                    sx={{ textAlign: 'center' }}
                  >
                    {error}
                  </Typography>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Login;
