// src/components/HomePage.tsx

import React, { useEffect, useState } from 'react';
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import awsconfig from '../aws-exports';
import { listSneakers, listArticles } from '../graphql/queries';
import Snackbar from '@mui/material/Snackbar';
import {
  Typography,
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Hidden,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme as useMuiTheme,
} from '@mui/material';
import Footer from './Footer';

Amplify.configure(awsconfig);

interface Sneaker {
  id: string;
  image_url: string;
  shoe_name: string;
  one_week_price: number;
  three_months_price: number;
  six_months_price: number;
  updatedAt: Date;
}

interface Article {
  id: string;
  slug: string;
  title: string;
  content: string;
  author: string;
  date: string;
  image_url: string;
}

// Create a custom MUI theme with League Spartan font
const customTheme = createTheme({
  typography: {
    fontFamily: 'League Spartan, Arial, sans-serif', // Updated to League Spartan
    h1: {
      fontWeight: 700,
      fontSize: '70px', // Default size for h1
      color: '#252525', // Text color
      lineHeight: 1.2,
    },
    h5: {
      fontWeight: 700,
      color: '#252525',
    },
    h6: {
      fontWeight: 700,
      color: '#252525',
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    background: {
      default: '#F4F5F7', // Grey background as per your requirement
    },
  },
});

const HomePage: React.FC = () => {
  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const [upcomingReleases, setUpcomingReleases] = useState<Sneaker[]>([]);
  const [pastReleases, setPastReleases] = useState<Sneaker[]>([]);
  const [articles, setArticles] = useState<Article[]>([]);
  const [welcomeSnackbarOpen, setWelcomeSnackbarOpen] = useState(false);
  const [welcomeName, setWelcomeName] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.fromSignUp) {
      setWelcomeName(location.state.name);
      setWelcomeSnackbarOpen(true);
    }
  }, [location]);

  useEffect(() => {
    const getSneakers = async () => {
      try {
        const result = await API.graphql(graphqlOperation(listSneakers));
        const data = result as any;

        const now = new Date().getTime();

        const allItems = data.data.listSneakers.items;
        const articleItems = allItems.filter((s: Sneaker) =>
          s.shoe_name.startsWith('ARTICLE'),
        );

        const upcoming = allItems.filter(
          (s: Sneaker) =>
            new Date(s.updatedAt).getTime() > now &&
            !s.shoe_name.startsWith('ARTICLE'),
        );
        const past = allItems.filter(
          (s: Sneaker) =>
            new Date(s.updatedAt).getTime() <= now &&
            !s.shoe_name.startsWith('ARTICLE'),
        );

        setUpcomingReleases(
          upcoming.sort(
            (a: Sneaker, b: Sneaker) =>
              new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
          ),
        );
        setPastReleases(
          past.sort(
            (a: Sneaker, b: Sneaker) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
          ),
        );
      } catch (error) {
        console.error('error fetching sneakers', error);
      }
    };

    const getArticles = async () => {
      try {
        const result = await API.graphql(graphqlOperation(listArticles));
        console.log('GraphQL response:', result);
        const data = result as any;
        const articles = data.data.listArticles.items;

        if (articles.length > 0) {
          // Sort articles by updatedAt from new to old
          const sortedArticles = articles.sort(
            (a: Article, b: Article) =>
              new Date(b.date).getTime() - new Date(a.date).getTime(),
          );

          setArticles(sortedArticles);
          sortedArticles.forEach((article: Article) => {
            console.log('Article image URL:', article.image_url);
          });
        } else {
          console.log('No articles found.');
        }
      } catch (error) {
        console.error('error fetching articles', error);
      }
    };

    getArticles();
    getSneakers();
  }, []);

  const handleArticleClick = (slug: string) => {
    navigate(`/article/${slug}`);
  };

  const handleSnackbarClose = () => {
    setWelcomeSnackbarOpen(false);
  };

  // Helper function to get the corresponding article for a sneaker
  const getArticleForSneaker = (sneakerId: string): Article | undefined => {
    return articles.find((article) => article.slug === sneakerId);
  };

  // Helper function to determine if "Go to the drop" button should be disabled
  const isDropButtonDisabled = (releaseDate: Date): boolean => {
    const now = new Date();
    const twoDaysAfterRelease = new Date(releaseDate);
    twoDaysAfterRelease.setDate(twoDaysAfterRelease.getDate() + 2);
    return now > twoDaysAfterRelease;
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          fontFamily: 'League Spartan, sans-serif', // Ensure the font is applied
          //backgroundColor: '#F4F5F7', // Set the overall background color
        }}
      >
        {/* Header Section */}
        <Hidden smDown>
          <Box
            sx={{
              backgroundColor: '#F4F5F7', // Grey background
              py: isMobile ? 6 : 8, // Padding top & bottom
              px: isMobile ? 2 : 4, // Padding left & right
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={4} alignItems="center">
                {/* Text Section */}
                <Grid item xs={12} md={6}>
                  <Typography
                    variant="h1"
                    sx={{
                      color: '#252525',
                      fontFamily: 'League Spartan, sans-serif',
                      fontSize: { xs: '20px', sm: '30px', md: '50px' },
                      fontWeight: 900,
                      mb: 4,
                    }}
                  >
                    GET A STEP AHEAD WITH DATA-DRIVEN SNEAKER VALUE PREDICTIONS.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/about-solepredict')}
                    sx={{
                      mt: 2,
                      color: '#252525',
                      bgcolor: 'white',
                      border: '1px solid #252525',
                      textTransform: 'none',
                      fontFamily: 'inherit',
                      fontSize: '1rem',
                      Shadow: 'none',
                      '&:hover': {
                        backgroundColor: '#0C9FD4',
                        Shadow: 'none',
                        color: 'white',
                        borderColor: '#0C9FD4',
                      },
                    }}
                  >
                    Get to Know Us
                  </Button>
                </Grid>

                {/* Image Section */}
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'center', md: 'flex-end' },
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#F4F5F7', // Matching grey box
                        padding: 2,
                        borderRadius: 2,
                        // boxShadow: 3,
                        maxWidth: { xs: '100%', md: '500px' },
                      }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/Travis-Scott-Fragment-Air-Jordan-1.jpg`}
                        alt="Travis Scott Fragment Air Jordan 1"
                        style={{
                          width: '150%',
                          height: 'auto',
                          borderRadius: '8px',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hidden>
        {/* The code below is for mobile */}
        <Hidden smUp>
          <Box
            sx={{
              backgroundColor: '#F4F5F7', // Grey background
              py: isMobile ? 3 : 8, // Padding top & bottom
              px: isMobile ? 2 : 4, // Padding left & right
            }}
          >
            <Container maxWidth="lg">
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="center"
              >
                {/* Text Section */}
                <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
                  <Typography
                    variant="h1"
                    sx={{
                      color: '#252525',
                      fontSize: { xs: '20px', sm: '30px', md: '50px' },
                      fontWeight: 900,
                      mb: 2, // Margin bottom for spacing
                    }}
                  >
                    GET A STEP AHEAD WITH DATA-DRIVEN SNEAKER VALUE PREDICTIONS.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => navigate('/about-solepredict')}
                    sx={{
                      mt: 2,
                      color: '#252525',
                      bgcolor: 'white',
                      border: '1px solid #252525',
                      textTransform: 'none',
                      fontFamily: 'inherit',
                      fontSize: '15',
                      '&:hover': {
                        backgroundColor: '#0C9FD4',
                        color: 'white',
                        borderColor: '#0C9FD4',
                      },
                    }}
                  >
                    Get to Know Us
                  </Button>
                </Grid>

                {/* Image Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#F4F5F7', // Matching grey box
                      borderRadius: 2,
                      maxWidth: { xs: '100%', md: '400px' }, // Reduced width
                      ml: 8, // Margin left to move it a bit to the right
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/Travis-Scott-Fragment-Air-Jordan-1.jpg`}
                      alt="Travis Scott Fragment Air Jordan 1"
                      style={{
                        width: '85%', // Reduced width of the image
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hidden>
        {/* Rest of your content */}
        <Box sx={{ flexGrow: 1 }} /> {/* Control the space above the content */}
        <Container sx={{ flexGrow: 1, py: 4 }} maxWidth="lg">
          {/* Snackbar for welcome message */}
          <Snackbar
            open={welcomeSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={`Welcome to SolePredict, ${welcomeName}!`}
          />
          {/* Upcoming Releases Section */}
          {upcomingReleases.length > 0 && (
            <>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  mt: 3,
                  fontWeight: 800,
                  marginBottom: 2,
                  fontFamily: 'inherit',
                  color: '#252525',
                  fontSize: '30px',
                }}
              >
                UPCOMING RELEASES
              </Typography>
              <Grid container spacing={4}>
                {upcomingReleases.map((sneaker: Sneaker) => {
                  const article = getArticleForSneaker(sneaker.id);
                  return (
                    <Grid item xs={12} sm={6} md={4} key={sneaker.id}>
                      <Card elevation={4} sx={{ height: '100%' }}>
                        <CardMedia
                          component="img"
                          height="250"
                          image={sneaker.image_url}
                          alt={sneaker.shoe_name}
                          onError={(e) => {
                            (e.target as HTMLImageElement).src =
                              '/default-image.jpg'; // Replace with your default image path
                          }}
                        />
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '20%',
                          }}
                        >
                          <Typography
                            variant="h6"
                            color="#252525"
                            gutterBottom
                            fontFamily="inherit"
                          >
                            {sneaker.shoe_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="#252525"
                            fontFamily="inherit"
                            sx={{ fontSize: '1rem' }}
                          >
                            Release Date:{' '}
                            {new Date(sneaker.updatedAt).toLocaleDateString()}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              mt: 2,
                            }}
                          >
                            {article && (
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                  textTransform: 'none',
                                  borderColor: 'black',
                                  color: 'black',
                                  backgroundColor: 'white',
                                  minWidth: isMobile ? '170px' : '150px',
                                  '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    borderColor: 'black',
                                  },
                                }}
                                onClick={() => handleArticleClick(article.id)}
                              >
                                SEE ANALYSIS
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                backgroundColor: isDropButtonDisabled(
                                  sneaker.updatedAt,
                                )
                                  ? '#F4F5F7' // Greyed out after drop
                                  : '#0C9FD4', // Active drop color
                                color: isDropButtonDisabled(sneaker.updatedAt)
                                  ? 'black' // Text color when disabled
                                  : 'white', // Text color when active
                                textTransform: 'none',
                                minWidth: isMobile ? '170px' : '150px',
                                '&:hover': {
                                  backgroundColor: isDropButtonDisabled(
                                    sneaker.updatedAt,
                                  )
                                    ? '#F4F5F7' // Maintain grey color on hover when disabled
                                    : '#0C9FD4', // Maintain active color on hover
                                },
                              }}
                              href={`https://${sneaker.id}`} // Ensure it's an absolute URL
                              target="_blank"
                              disabled={isDropButtonDisabled(sneaker.updatedAt)}
                            >
                              {isDropButtonDisabled(sneaker.updatedAt)
                                ? 'DROP PASSED'
                                : 'GO TO THE DROP'}
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}

          {/* Past Releases Section */}
          {pastReleases.length > 0 && (
            <>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  mt: 3,
                  marginBottom: 2,
                  fontWeight: 800,
                  fontFamily: 'inherit',
                  color: '#252525',
                  fontSize: '30px',
                }}
              >
                PAST RELEASES
              </Typography>
              <Grid container spacing={4}>
                {pastReleases.map((sneaker: Sneaker) => {
                  const article = getArticleForSneaker(sneaker.id);
                  return (
                    <Grid item xs={12} sm={6} md={4} key={sneaker.id}>
                      <Card elevation={4} sx={{ height: '100%' }}>
                        <CardMedia
                          component="img"
                          height="250"
                          image={sneaker.image_url}
                          alt={sneaker.shoe_name}
                          onError={(e) => {
                            (e.target as HTMLImageElement).src =
                              '/default-image.jpg'; // Replace with your default image path
                          }}
                        />
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '20%',
                          }}
                        >
                          <Typography
                            variant="h6"
                            color="#252525"
                            fontFamily="inherit"
                          >
                            {sneaker.shoe_name}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="#252525"
                            fontFamily="inherit"
                            sx={{ fontSize: '1rem' }}
                          >
                            Released on:{' '}
                            {new Date(sneaker.updatedAt).toLocaleDateString()}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              mt: 2,
                            }}
                          >
                            {article && (
                              <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                  textTransform: 'none',
                                  borderColor: 'black',
                                  color: 'black',
                                  backgroundColor: 'white',
                                  minWidth: isMobile ? '170px' : '150px',
                                  '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    borderColor: 'black',
                                  },
                                }}
                                onClick={() => handleArticleClick(article.id)}
                              >
                                SEE ANALYSIS
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{
                                backgroundColor: isDropButtonDisabled(
                                  sneaker.updatedAt,
                                )
                                  ? '#F4F5F7' // Greyed out after drop
                                  : '#0C9FD4', // Active drop color
                                color: isDropButtonDisabled(sneaker.updatedAt)
                                  ? 'black' // Text color when disabled
                                  : 'white', // Text color when active
                                textTransform: 'none',
                                minWidth: isMobile ? '170px' : '150px',
                                '&:hover': {
                                  backgroundColor: isDropButtonDisabled(
                                    sneaker.updatedAt,
                                  )
                                    ? '#F4F5F7' // Maintain grey color on hover when disabled
                                    : '#0C9FD4', // Maintain active color on hover
                                },
                              }}
                              href={`https://${sneaker.id}`} // Ensure it's an absolute URL
                              target="_blank"
                              disabled={isDropButtonDisabled(sneaker.updatedAt)}
                            >
                              {isDropButtonDisabled(sneaker.updatedAt)
                                ? 'DROP PASSED'
                                : 'GO TO THE DROP'}
                            </Button>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default HomePage;
