import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button, TextField, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // Step 1 for requesting code, Step 2 for resetting password
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');

  const requestResetCode = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    try {
      await Auth.forgotPassword(email);
      setStep(2); // Move to next step
    } catch (error) {
      console.error('Error requesting reset code: ', error);
      setError('Failed to request password reset. Please try again.');
    }
  };

  const resetPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      alert(
        'Password reset successfully. Please login with your new password.',
      );
      navigate('/login');
    } catch (error) {
      console.error('Error resetting password: ', error);
      setError(
        'Failed to reset password. Please check your information and try again.',
      );
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        <Box
          component="form"
          onSubmit={step === 1 ? requestResetCode : resetPassword}
          sx={{ mt: 3 }}
        >
          {step === 1 && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#0C9FD4',
                }}
              >
                Request Reset Code
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="code"
                label="Reset Code"
                type="text"
                id="code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: '#0C9FD4',
                }}
              >
                Reset Password
              </Button>
            </>
          )}
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordReset;
