import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { Button, TextField, Container, Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

const EmailVerification: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location.state as { email: string } | null;
  const [email, setEmail] = useState(initialState?.email || '');
  const [code, setCode] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [password, setPassword] = useState('');

  const handleVerification = async (event: React.FormEvent) => {
    event.preventDefault();
    setVerificationError('');

    try {
      await Auth.confirmSignUp(email, code);
      await Auth.signIn(email, password);
      navigate('/');
    } catch (error) {
      setVerificationError(
        'Failed to verify email. Please check the code and try again.',
      );
      console.error('Error verifying email:', error);
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ fontFamily: 'Lato, sans-serif' }}
    >
      {' '}
      {/* Apply Lato font here */}
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ fontFamily: 'inherit' }}>
          {' '}
          {/* Ensures Lato font is used */}
          Verify Your Email
        </Typography>
        <Box
          component="form"
          onSubmit={handleVerification}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="code"
            label="Verification Code"
            type="text"
            id="code"
            autoComplete="one-time-code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, fontFamily: 'inherit' }} // Ensures Lato font is used
          >
            Verify Email
          </Button>
          {verificationError && (
            <Typography
              color="error"
              variant="body2"
              sx={{ fontFamily: 'inherit' }}
            >
              {' '}
              {/* Ensures Lato font is used */}
              {verificationError}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default EmailVerification;
