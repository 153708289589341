import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, TextField, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import awsconfig from '../aws-exports';

Auth.configure(awsconfig);

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const checkCurrentUser = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        navigate('/subscribe');
      } catch {
        // User not logged in, stay on signup page
      }
    };

    checkCurrentUser();
  }, [navigate]);

  const validatePhone = (phone: string) => {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  };

  const handleSignUp = async (event: React.FormEvent) => {
    event.preventDefault();
    const formattedPhone = `+1${phone}`;
    setPhoneError(!validatePhone(formattedPhone));
    if (!validatePhone(formattedPhone)) return;
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          phone_number: formattedPhone,
          name,
        },
      });
      navigate('/verify-email', { state: { email } });
    } catch (error) {
      let message = 'An unexpected error occurred.';
      if (
        error instanceof Error &&
        error.message.includes(
          'An account with the given email already exists.',
        )
      ) {
        message = error.message;
      }
      setErrorMessage(message);
    }
  };

  const handleSocialSignIn = async (provider: string) => {
    try {
      const providerMapping: Record<string, string> = {
        Google: 'Google',
        Facebook: 'Facebook',
        Apple: 'SignInWithApple',
      };

      await Auth.federatedSignIn({
        customProvider: providerMapping[provider],
      });
    } catch (error) {
      console.error('Social sign-in error:', error);
      setErrorMessage('An error occurred during social sign-in.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'white',
        fontFamily: 'Lato, sans-serif',
        alignItems: 'center',
        mt: -6, // Move the entire Box 50px up
      }}
    >
      {/* Content Box */}
      <Box
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'inherit',
          padding: 2, // Optional: add padding for better spacing
          mt: 6, // Add margin-top to move the content down within the Box
        }}
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            fontFamily: 'Lato, sans-serif',
            border: '1px solid #ccc', // Add border around the container
            padding: 3, // Add padding inside the container
            borderRadius: 2, // Optional: add border radius for rounded corners
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontFamily: 'inherit',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontFamily: 'inherit' }}
            >
              Create Account
            </Typography>
            <Box
              component="form"
              onSubmit={handleSignUp}
              noValidate
              sx={{ mt: 1, fontFamily: 'inherit', alignItems: 'center' }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                onChange={(e) => setName(e.target.value)}
                sx={{ fontFamily: 'inherit', alignItems: 'center' }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{ fontFamily: 'inherit' }}
              />
              <TextField
                error={phoneError}
                margin="normal"
                required
                fullWidth
                name="phone"
                label="Phone Number"
                type="tel"
                id="phone"
                autoComplete="tel"
                onChange={(e) => setPhone(e.target.value)}
                sx={{ fontFamily: 'inherit' }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                sx={{ fontFamily: 'inherit' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontFamily: 'inherit',
                  backgroundColor: '#0C9FD4',
                  textTransform: 'none', // Prevent button text from being capitalized
                }}
              >
                Sign Up
              </Button>
              {errorMessage && (
                <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                  {errorMessage}
                </Typography>
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{ mt: 2, mb: 2, textAlign: 'center' }}
            >
              or sign up with
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <Button
                variant="outlined"
                onClick={() => handleSocialSignIn('Google')}
                sx={{ textTransform: 'none' }}
              >
                Google
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSocialSignIn('Facebook')}
                sx={{ textTransform: 'none' }}
              >
                Facebook
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleSocialSignIn('Apple')}
                sx={{ textTransform: 'none' }}
              >
                Apple
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default SignUp;
