import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import Footer from './Footer';

const UpcomingPage: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: 'white',
        fontFamily: 'Lato, sans-serif', // Apply Lato font here
      }}
    >
      {/* Content Box */}
      <Box
        sx={{
          flex: '1 0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          fontFamily: 'inherit', // Ensure inheritance of Lato font
        }}
      >
        <Container>
          <Paper
            sx={{
              padding: 4,
              textAlign: 'center',
              boxShadow: '0 3px 5px 2px rgba(27, 27, 27, .3)',
              backgroundColor: '#51555B',
              fontFamily: 'inherit', // Ensure inheritance of Lato font
            }}
            elevation={5}
          >
            <Typography
              variant="h3"
              sx={{
                marginBottom: 3,
                color: 'white',
                fontWeight: 600,
                fontFamily: 'inherit', // Ensure inheritance of Lato font
              }}
            >
              Upcoming
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: 'white',
                fontFamily: 'inherit', // Ensure inheritance of Lato font
              }}
            >
              SolePredict strives to constantly innovate. Check here for
              information on upcoming features.
            </Typography>
          </Paper>
        </Container>
      </Box>
      {/* Spacer Box */}
      <Box sx={{ flex: '0 0 auto', fontFamily: 'inherit' }} />{' '}
      {/* Ensure inheritance of Lato font */}
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default UpcomingPage;
